/* .MuiFormLabel-root-16, .MuiInputBase-root-36 {
    font-size: 1.5rem !important;
}

.MuiButton-root-53 {
    font-size: 1.1rem !important;
} */

.form-ui-bootstrap {
    width: 100%;
}

.form-ui-bootstrap label {
    font-size: 1.5rem;
}

.form-ui-bootstrap div {
    font-size: 1.5rem;
}

.form-ui-bootstrap p {
    font-size: 1rem;
}

.error {
    color: red;
    font-size: 12px;
}

.instagram-button {
    background-color: #405DE6; /* Cor primária do Instagram */
    color: white; /* Cor do texto */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
    cursor: pointer; /* Cursor ao passar */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: background-color 0.3s; /* Transição suave da cor de fundo */
}
  
.instagram-button:hover {
    background-color: #3f4b5a; /* Cor de fundo no hover */
}

.instagram-button-cancel {
    background-color: #ED4956; /* Vermelho */
    color: white; /* Cor do texto */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
    cursor: pointer; /* Cursor ao passar */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: background-color 0.3s; /* Transição suave da cor de fundo */
}
  
.instagram-button-cancel:hover {
    background-color: #c23842; /* Cor de fundo no hover */
}

.facebook-button {
    background-color: #1877F2; /* Cor primária do Facebook */
    color: white; /* Cor do texto */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
    cursor: pointer; /* Cursor ao passar */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: background-color 0.3s; /* Transição suave da cor de fundo */
}
  
.facebook-button:hover {
    background-color: #155B9E; /* Cor de fundo no hover */
}

.facebook-button-cancel {
    background-color: #D9534F; /* Vermelho de cancelamento */
    color: white; /* Cor do texto */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
    cursor: pointer; /* Cursor ao passar */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: background-color 0.3s; /* Transição suave da cor de fundo */
}
  
.facebook-button-cancel:hover {
    background-color: #C12E2A; /* Cor de fundo no hover */
}

.mt-5 {
    margin-top: 3rem;
}

.progress-upload-images-integracao {
    margin: 0 auto;
    text-align: center;
}